<template>
  <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <main class="container mb-5">
    <router-view />
  </main>
</template>
<script>
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'App',
  data() {
    return {
    };
  },
  components: {},
  computed: {
  },
  created() {
    WebSocketService.connect("/chat");
  },
  mounted() {
  },
  methods: {
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
