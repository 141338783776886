import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://localhost:3033";
// app.config.globalProperties.$auth = "http://auth.com:3000";

axios.defaults.baseURL = "https://localbackend-absensi.sitepgri.com";
app.config.globalProperties.$auth = "https://auth.sitepgri.com";

app.use(router);
app.use(store);
app.use(VueAxios, axios);

app.mount("#app");
